.container-1{
    margin:10px;
    border: solid red 1px;
    display: flex;
}

.canvas{
    margin-top:70px;
    width: 100%;
    border: 1px black;
}

.palette{
    margin:20px;
    width: 90%;
}

.canvas-emblem{
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.canvas-title{
    color: white;
}

.canvas-title:hover{
    color: red;
}


.deploy-button {
    background-color: rgb(25,118,210); /* Green */
    border: none;
    
    color: white;
    padding: 7px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    margin: 2px 10px 2px 1px;
  }
  
  .deploy-text{
    text-decoration: none;
    color:white;
  }
  
  .deploy-text:hover{
    text-decoration: none;
    color:white;
  }