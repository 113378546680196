.palette-button {
  background-color: #0086bb; /* Green */
  border: none;
  width: 100%;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin: 2px 10px 2px 1px;
}
.publish-button {
  background-color: #009619; /* Green */
  border: none;
  width: 100%;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin: 2px 10px 2px 1px;
}

.publish-text{
  text-decoration: none;
  color:white;
}

.publish-text:hover{
  text-decoration: none;
  color:white;
}