.publish__canvas{
    overflow: auto;
}
.publish__goback_link{
    text-decoration: none;
    color: beige;
    display: inline;
}

.publish__goback_link:hover{
    text-decoration: none;
    color: beige;
}

.publish__goback{
    padding:2px;
}
.publish__goback_button{
    border-radius: 1px;
}
.pubilsh__canvas_wrapper{
    margin: 20px;
}